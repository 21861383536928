import React, { useEffect, useState } from 'react';
import '../styles/index.css';

function Index() {
  const [date, setDate] = useState(null);
  useEffect(() => {
    async function getDate() {
      const res = await fetch('/api/date');
      const newDate = await res.text();
      setDate(newDate);
    }
    getDate();
  }, []);

  return (
    <main>
       <img src="https://i.ibb.co/2y9gxsw/ghinea3.png" alt="" />
    </main>
  );
}

export default Index;
